<template>
  <div class="login">
    <form action="" @submit.prevent="login">
      <div class="loginLogo">
        <img src="../../assets/img/logo.png" alt="" />
      </div>
      <div class="title">
        <h5>{{ $t("admin.login_header") }}</h5>
      </div>
      <div class="input">
        <input
          type="text"
          :placeholder="$t('admin.email')"
          v-model="form.email"
        />
      </div>
      <div class="input">
        <input
          :type="passtype"
          :placeholder="$t('admin.password')"
          v-model="form.password"
        />
        <i :class="show_pass" @click="showPassword"></i>
      </div>
      <router-link to="admin/1" style="text-decoration: none">
        {{ $t("admin.forgot_password") }}
      </router-link>
      <div class="button">
        <button type="submit">
          <span v-show="!isLoading">{{ $t("admin.submit") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
          <!-- {{ $t("admin.submit") }} -->
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import spinner from "../../components/spinner/Spinner.vue";

export default {
  name: "adminlogin",
  components: {
    spinner,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: "",
      passtype: "password",
      show_pass: "far fa-eye-slash",
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      this.$store.dispatch("auth/login", this.form).then(() => {
        this.isLoading = false;
      });
    },
    showPassword() {
      if (this.passtype === "password") {
        this.passtype = "text";
        this.show_pass = "far fa-eye";
      } else {
        this.passtype = "password";
        this.show_pass = "far fa-eye-slash";
      }
    },
  },
};
</script>

<style scoped>
.loginLogo img {
  width: 250px;
  display: flex;
  margin: auto;
}

.title {
  display: flex;
  justify-content: center;
}
.title h5 {
  font-family: mtavruli;
  color: #666666;
}
a {
  color: #666666;
}
a:hover {
  color: black;
  font-weight: bold;
}
.login {
  margin-top: 0 !important;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  margin: auto;
}
.login form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
}
.login .input {
  position: relative;
}
.login input {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 1px solid #dadada;
  outline: none;
  padding-left: 20px;
}
.login input:focus {
  border: 1px solid #7e5493;
  transition: 0.3s;
}
.login .input i {
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}
.login button {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}

@media all and (max-width: 700px) {
  .login input {
    width: 100%;
    height: 50px;
    border-radius: 0px;
    border: 1px solid #dadada;
    outline: none;
    padding-left: 20px;
  }
  .login form {
    width: 80%;
  }
}
</style>
